import { Role } from '../../data/user';
import API from '../../utils/api';

const URL_PREFIX = '/api/user';

export const userService = {
  login,
  signUp,
  logout,
  getUser,
  getUsers,
  changeRole,
  resetPassword,
  changeActive,
  signUpFromUser,
  updateUser,
};

function login(data: { email: string; password: string }) {
  return API.post(`${URL_PREFIX}/login`, data);
}
function logout() {
  return API.get(`${URL_PREFIX}/logout`);
}
function signUp(data: { email: string; password: string; role?: Role; active?: boolean }) {
  return API.post(`${URL_PREFIX}/signup`, data);
}
function signUpFromUser(data: { email: string; password: string }) {
  return API.post(`${URL_PREFIX}/signupFromUser`, data);
}
function getUser() {
  return API.get(`${URL_PREFIX}/getUser`);
}
function getUsers(role: Role) {
  return API.post(`${URL_PREFIX}/getUsers`, { role });
}
function changeRole(data: { _id: string; role: Role }) {
  return API.post(`${URL_PREFIX}/changeRole`, data);
}
function resetPassword(data: { email: string; password: string }) {
  return API.post(`${URL_PREFIX}/resetPassword`, data);
}
function changeActive(data: { _id: string; active: boolean }) {
  return API.post(`${URL_PREFIX}/changeActive`, data);
}
function updateUser(data: { email: string; active: boolean; role: Role }) {
  return API.post(`${URL_PREFIX}/updateUser`, data);
}
