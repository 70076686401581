import { IGame } from '../../data/game';
import API from '../../utils/api';

const URL_PREFIX = '/api/games';

export const gameService = {
  getProdGames,
  getDevGames,
  getGameData,
  getGamesByType,
  getGame,
  searchGameHaveCondition,
  addGame,
  addGameProd,
  addGameFlatform,
  updateGameProd,
  getLinkImage,
  deleteGameProd,
  getGrade,
  addGrade,
  getGradeById,
  getUnit,
  addUnit,
  getUnitById,
  renameUnit,
  removeUnit,
  renameGrade,
  removeGrade,
  getGameInUnit,
  getGameNotUnit,
  insertGametoUnit,
  removeGamefromUnit,
  getRawData,
  addGameByRawData,
  getGameTypes,
  changeDataGame,
};

function getGameTypes() {
  return API.post(`${URL_PREFIX}/getGameTypes`);
}
function getProdGames() {
  return API.get(`${URL_PREFIX}/prod`);
}
function getDevGames() {
  return API.get(`${URL_PREFIX}/dev`);
}

function getGamesByType(gameType) {
  return API.get(`${URL_PREFIX}/getGamesByType/${gameType}`);
}

function getGame(gameId) {
  return API.get(`${URL_PREFIX}/${gameId}`);
}

function getGameData(gameId) {
  return API.get(`${URL_PREFIX}/prod/${gameId}`);
}

function getGrade() {
  return API.get(`/api/grade/`);
}

function getGradeById(gradeId) {
  return API.get(`/api/grade/${gradeId}`);
}

function getUnitById(unitId: string) {
  return API.get(`/api/unit/${unitId}`);
}

function getUnit(gradeId) {
  return API.get(`/api/unit/?grade=${gradeId}`);
}
function getGameInUnit(unitId) {
  return API.get(`/api/games/gameInUnit/?unitId=${unitId}`);
}
function getRawData(gameId) {
  return API.get(`/api/games/getRawData/${gameId}`);
}
function searchGameHaveCondition(data: { condition: Object }) {
  return API.post(`/api/games/search`, data);
}
function insertGametoUnit(gameId, unitId) {
  const input = {
    gameId: gameId,
    unitId: unitId,
  };
  return API.put(`/api/games/insertToUnit/`, input);
}

function getGameNotUnit(key) {
  return API.get(`/api/games/gameNotHasUnit/?key=${key}`);
}
function renameUnit(data: { new_name: string; unit_id: string }) {
  return API.post(`/api/unit/rename`, data);
}
function removeUnit(id) {
  return API.delete(`/api/unit/?id=${id}`);
}
function removeGamefromUnit(id) {
  return API.delete(`${URL_PREFIX}/removeFromUnit/?gameId=${id}`);
}

function deleteGameProd(gameId) {
  return API.delete(`${URL_PREFIX}/${gameId}`);
}
function renameGrade(data: { new_name: string; grade_id: string }) {
  return API.post(`/api/grade/rename`, data);
}

function removeGrade(gradeId) {
  return API.delete(`/api/grade/?id=${gradeId}`);
}

function addGame(game) {
  return API.post(`${URL_PREFIX}`, game);
}

function addGrade(data) {
  return API.post(`/api/grade/add`, data);
}
function addUnit(data) {
  return API.post(`/api/unit/add`, data);
}

function addGameProd(game) {
  return API.post(`${URL_PREFIX}/add`, game);
}
function addGameByRawData(game) {
  return API.post(`${URL_PREFIX}/addByRawData`, game);
}
function addGameFlatform(game) {
  return API.post(`${URL_PREFIX}/addGameFlatform`, game);
}

function updateGameProd(data: { game_data: IGame; describe?: string }) {
  return API.put(`${URL_PREFIX}/update`, data);
}

function getLinkImage(data: FormData) {
  return API.post('/api/media/getLinkResources', data);
}
function changeDataGame(data: { data_change: Object; data_condition: Object }) {
  return API.post(`${URL_PREFIX}/edit_data`, data);
}
