import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const store = (initialState) => {
  const middleware = [thunk];

  const _store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)));

  return _store;
};

export default store;
