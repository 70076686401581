import React from 'react';

interface Props {
  label: string;
  description: string;
  multiline: boolean;
  output: string;
  format?: string;
  placeholder?: string;
  defaultValue?: string;
  isNumber?: boolean;
  handleChangeData: (field, value) => void;
}

const InputField: React.FC<Props> = (props) => {
  return (
    <div>
      {!props.multiline ? (
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              data-toggle="tooltip"
              data-placement="top"
              title={props.description}
              id="inputGroup-sizing-default">
              {props.label}
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            defaultValue={props.defaultValue}
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            onChange={(e) => {
              console.log(props.format + props.output);
              if (props.format) {
                props.handleChangeData(props.format + props.output, e.target.value);
              } else {
                props.handleChangeData(props.output, e.target.value);
              }
            }}
          />
        </div>
      ) : (
        <textarea name={props.label} rows={9} placeholder="field.placeholder"></textarea>
      )}
    </div>
  );
};

export default InputField;
