import React from 'react';
import './style.scss';
interface Props {
  option: Array<any>;
  label?: string;
  description?: string;
  output: string;
  handleChangeData: (field, value) => void;
}

const SelectBox: React.FC<Props> = (props) => {
  return (
    <div className="select-box">
      <div className="wrapper input-group mb-3">
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            data-toggle="tooltip"
            data-placement="top"
            title={props.description}
            id="inputGroup-sizing-default">
            {props.label}
          </span>
        </div>
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={(e) => {
            props.handleChangeData(props.output, e.target.value);
          }}>
          {props.option?.map((element) => {
            return <option value={element}>{element}</option>;
          })}
        </select>
      </div>
    </div>
  );
};

export default SelectBox;
