import React from 'react';
import FieldBuilder from './field-builder';

interface Props {
  fields: any;
  handleChangeData?: (field, value) => void;
  handleChangeResource?: (field, value) => void;
}

const DynamicForm: React.FC<Props> = (props) => {
  return (
    <div>
      {props.fields.map((ele, i) => {
        return (
          <FieldBuilder
            key={ele.type}
            data={ele}
            handleChangeData={props.handleChangeData}
            handleChangeResource={props.handleChangeResource}
          />
        );
      })}
    </div>
  );
};

export default DynamicForm;
