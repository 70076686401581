import React from 'react';
import CheckBox from './check-box';
import Container from './container';
import Dropdown from './dropdown';
import InputField from './input-feild';
import Editor from './monaco-editor';
import Resource from './resource';
import Selectbox from './selectbox';
import UploadZone from './upload-zone';

interface Props {
  data: any;
  format?: string;
  handleChangeData?: (field, value) => void;
  handleChangeResource?: (field, value) => void;
}

const FieldBuilder: React.FC<Props> = (props) => {
  const renderComponent = () => {
    switch (props.data.type) {
      case 'image':
        return (
          <Resource
            option={props.data.option}
            label={props.data.label}
            description={props.data.description}
            isImage={true}
            handleChangeResource={props.handleChangeData}
          />
        );
      case 'audio':
        return (
          <Resource
            option={props.data.option}
            label={props.data.label}
            description={props.data.description}
            isImage={false}
            handleChangeResource={props.handleChangeResource}
          />
        );
      case 'option':
        return (
          <Selectbox
            option={props.data.option}
            label={props.data.label}
            description={props.data.description}
            output={props.data.output}
            handleChangeData={props.handleChangeData}
          />
        );
      case 'checkbox':
        return (
          <CheckBox
            label={props.data.label}
            description={props.data.description}
            defaultValue={props.data.defaultValue}
            output={props.data.output}
            handleChangeData={props.handleChangeData}
          />
        );
      case 'dropdown':
        return <Dropdown name={props.data.name} data={props.data.data} />;
      case 'editor':
        return (
          <Editor
            change={props.data.change}
            width={props.data.width}
            height={props.data.height}
            value={props.data.value}
          />
        );
      case 'string':
      case 'number':
        return (
          <InputField
            description={props.data.description}
            label={props.data.label}
            defaultValue={props.data.defaultValue}
            multiline={props.data.multiline || false}
            output={props.data.output}
            format={props.format}
            handleChangeData={props.handleChangeData}
          />
        );
      case 'container':
        return (
          <Container
            label={props.data.label}
            description={props.data.description}
            form={props.data.form}
            format={props.format ? props.format + props.data.format : props.data.format}
            handleChangeResource={props.handleChangeResource}
            handleChangeData={props.handleChangeData}></Container>
        );
      case 'uploadzone':
        return (
          <UploadZone onDrop={props.data.onDrop} accept={props.data.accept} placeHolder={props.data.placeHolder} />
        );
      default:
        return Object.keys(props.data).map((key, index) => {
          return (
            <FieldBuilder
              key={index}
              data={props.data[key]}
              handleChangeData={props.handleChangeData}
              handleChangeResource={props.handleChangeResource}
            />
          );
        });
    }
  };

  return (
    <div className="form-group">
      {props.data.type === 'title' ? (
        <h3>{props.data.title}</h3>
      ) : (
        <div style={{ width: '100%' }}>
          {props.data.title && props.data.title !== '' ? (
            <label className="form-control-label">{props.data.title}</label>
          ) : null}
          <div>{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default FieldBuilder;
