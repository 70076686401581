import * as actionTypes from './actionTypes';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETGAMES:
      return {
        items: action.res,
      };
    case actionTypes.GETGAME:
      return {
        item: action.res,
      };
    default:
      return state;
  }
};

export default reducer;
