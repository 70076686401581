const databaseQuery = window.electronAPI?.databaseQuery;
const databaseUpdate = window.electronAPI?.databaseUpdate;

export const getCacheByUrl = (url) => {
  return databaseQuery('SELECT * FROM Caches WHERE Url = ? LIMIT 1', [url]);
};

export const insertCache = (url, data) => {
  return databaseUpdate('INSERT INTO Caches (Url, Data) VALUES (?, ?)', [
    url,
    JSON.stringify(data),
  ]);
};

export const deleteCache = (url) => {
  return databaseUpdate('DELETE FROM Caches WHERE Url = ?', [url]);
};
