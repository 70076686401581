/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import FormInput from './FormInput';
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import { gameService } from '../../services/game/api';
import SearchBox from './SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Modals from './Modals';
import Burger from './Burger';
import Menu from './Menu';
import './style.scss';
import { userService } from '../../services/user/api';
import { Link, useNavigate } from 'react-router-dom';
// import { Role } from '../Users';
import ErrorWarning from './ErrorWarning';
import { Result } from '../../services/game/formatGameData';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState(false);
  const [grade, setGrade] = useState(undefined);
  const [grades, setGrades] = useState([]);
  const [unit, setUnit] = useState(undefined);
  const [units, setUnits] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [unitName, setUnitName] = useState('');
  const [games, setGames] = useState([]);
  const [game, setGame] = useState(undefined);
  const [isCreUnit, setIsCreUnit] = useState(false);
  const [gameNoUnit, setGameNoUnit] = useState([]);
  const [activeKey, setActiveKey] = useState(0);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const menuRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [extractResourcesResult, setExtractResourcesResult] = useState<Result>({
    resources: [],
    errors: [],
    duplicatedKeys: [],
    duplicatedPaths: [],
  });

  useEffect(() => {
    navigate('/home');
    // userService
    //   .getUser()
    //   .then((res) => {
    //     if (res.data.data.role === Role.userwait) {
    //       navigate('/user-wait');
    //     } else {
    //       setIsLogin(true);
    //     }
    //   })
    //   .catch((err) => {
    //     // toast.error('Không có quyền vào trang này');
    //     navigate('/auth/login');
    //   });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setIsVisible(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (isLogin) {
      loadGrades();
    }
  }, [isLogin]);
  useEffect(() => {
    if (grade) {
      const { _id } = grade;
      gameService.getUnit(_id).then((res) => {
        const data = res.data.data;
        setUnit(data[0]);
        setActiveKey(0);
        setUnits(data);
      });
    }
  }, [grade]);

  useEffect(() => {
    loadGames();
    // eslint-disable-next-line
  }, [unit]);
  const searchGameHasNotUnit = (value) => {
    gameService.getGameNotUnit(value).then((res) => {
      setGameNoUnit(res.data.data);
    });
  };
  const loadGames = () => {
    if (unit) {
      const { _id } = unit;

      gameService.getGameInUnit(_id).then((res) => {
        const data = res.data.data;
        setGame(undefined);
        setGames(data);
      });
    }
  };
  const loadUnits = () => {
    if (grade) {
      const { _id } = grade;
      gameService.getUnit(_id).then((res) => {
        const data = res.data.data;
        setUnits(data);
        setGrade(grade);
      });
    } else {
      setUnits([]);
    }
  };
  const loadGrades = () => {
    gameService.getGrade().then((res) => {
      const data = res.data.data;
      setGrade(data[0]);
      setGrades(data);
      setUnit(undefined);
    });
  };
  const handleDeleteUnit = () => {
    if (unit) {
      gameService
        .removeUnit(unit._id)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || 'Không có quyền truy cập');
        });
    }

    setTimeout(() => {
      if (grade) {
        const { _id } = grade;
        gameService.getUnit(_id).then((res) => {
          const data = res.data.data;
          setGrade(grade);
          setUnits(data);
          setUnit(data[0]);
          setActiveKey(0);
        });
      }
    }, 500);
  };
  const handleDeleteGrade = () => {
    if (grade) {
      gameService
        .removeGrade(grade._id)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || 'Không có quyền truy cập');
        });
    }
    setTimeout(() => {
      gameService.getGrade().then((res) => {
        const data = res.data.data;
        setGrade(data[data.length - 1]);
        setGrades(data);
        setUnit(undefined);
      });
    }, 500);
  };
  const handleSaveGrade = () => {
    const data = {
      title: folderName,
    };

    gameService
      .addGrade(data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Không có quyền truy cập');
      });
    setShow(false);
    setTimeout(() => {
      gameService.getGrade().then((response) => {
        const resData = response.data.data;
        setGrade(resData[resData.length - 1]);
        setGrades(resData);
        setUnit(undefined);
        setOpen(!open);
      });
    }, 300);
  };
  const handleSaveUnit = () => {
    const { _id } = grade;
    const data = {
      title: `Unit ${units.length + 1}`,
      gradeId: _id,
    };

    gameService
      .addUnit(data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Không có quyền truy cập');
      });
    setShow(false);
    setTimeout(() => {
      loadUnits();
      if (grade) {
        gameService.getUnit(_id).then((res) => {
          const resData = res.data.data;
          setUnit(resData[resData.length - 1]);
          setActiveKey(resData.length - 1);
          setUnits(resData);
          setGame(undefined);
          setGrade(grade);
        });
      } else {
        setUnits([]);
      }
    }, 200);
  };
  const getTitle = (g) => {
    const { title } = g;
    return title;
  };

  return (
    <>
      {isLogin ? (
        <>
          <div>
            <div>
              <h1 className="p-2 bg-info text-white">
                <b className="d-inline" style={{ fontSize: '2rem' }}>
                  {grade ? `${getTitle(grade)}` : `Chưa có lớp`}
                </b>
                <div
                  className="btn btn-light float-right"
                  onClick={(e) => {
                    userService.logout().then((res) => {
                      document.cookie.split(';').forEach(function (c) {
                        document.cookie = c
                          .replace(/^ +/, '')
                          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
                      });
                      navigate('/auth/login');
                    });
                  }}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </div>
                <div
                  className="btn btn-light float-right mr-5"
                  onClick={() => {
                    let confirm = window.confirm('Bạn có muốn xóa lớp này!');
                    if (confirm) {
                      handleDeleteGrade();
                    }
                  }}>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </h1>
              <Tab.Container id="left-tabs-example" defaultActiveKey="0" activeKey={activeKey}>
                <Row className="mt-4 px-4" style={{ maxWidth: '110rem', margin: 'auto' }}>
                  <Col sm={1} style={{ paddingRight: 0 }}>
                    {units && units.length > 0 ? (
                      <Nav variant="pills" className="flex-row" style={{ marginBottom: '10px' }}>
                        {units.map((item, index) => {
                          const { title } = item;
                          return (
                            <>
                              <Nav.Item key={index}>
                                <Nav.Link
                                  eventKey={`${index}`}
                                  onClick={() => {
                                    setActiveKey(index);
                                    setUnit(item);
                                    setGame(undefined);
                                  }}>{`${title}`}</Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        })}
                      </Nav>
                    ) : null}
                    <Nav
                      variant="pills"
                      className="flex-row add-unit-btn"
                      onClick={() => {
                        // setIsCreUnit(true);
                        // setShow(true);
                        handleSaveUnit();
                      }}>
                      <Nav.Item style={{ textAlign: 'center' }}>
                        <Nav.Link className="add-unit-icon">
                          <FontAwesomeIcon icon={faPlus} />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={11} style={{ paddingLeft: 0 }}>
                    <Tab.Content className="card">
                      {units.map((item, index) => {
                        const { _id } = item;
                        return (
                          <Tab.Pane eventKey={`${index}`} key={index}>
                            <div className="">
                              <div className="card-body">
                                <div>
                                  <div
                                    className="btn btn-light float-right"
                                    onClick={() => {
                                      let confirm = window.confirm('Bạn có muốn xóa Unit này!');
                                      if (confirm) {
                                        handleDeleteUnit();
                                      }
                                    }}>
                                    <FontAwesomeIcon icon={faTrash} />
                                  </div>
                                  <SearchBox
                                    isVisible={isVisible}
                                    childRef={wrapperRef}
                                    inputRef={inputRef}
                                    setVisible={(e) => {
                                      setIsVisible(e);
                                    }}
                                    onChange={(e) => {
                                      // setSearch(e.target.value.trim());
                                      searchGameHasNotUnit(e.target.value.trim());
                                    }}
                                    games={gameNoUnit}
                                    unitId={_id}
                                    onSave={() => {
                                      loadGames();
                                    }}
                                  />
                                </div>
                                <div className="d-block px-3">
                                  <div className="row">
                                    {games.map((g, i) => {
                                      return (
                                        <div className="col-xl-4 col-md-4 col-sm-6 col-12 mt-4" key={i}>
                                          <div className="row" style={{ minWidth: '100%' }}>
                                            <div className="btn btn-light" key={`"i"+${i}`}>
                                              <Link to={`/games/${g._id}`}>
                                                {i + 1}. {g.title}
                                              </Link>
                                            </div>
                                            <div
                                              className="btn btn-light"
                                              key={i}
                                              onClick={() => {
                                                setGame(g);
                                              }}>
                                              <FontAwesomeIcon icon={faEdit} />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        );
                      })}
                      <div className="d-flex justify-content-start">
                        <div className="card-body" style={{ maxWidth: '70rem', textAlign: 'left' }}>
                          {unit ? (
                            <FormInput
                              gameTitle={game?.title || ''}
                              _gameId={game?._id || ''}
                              unitId={unit._id}
                              error_warning={extractResourcesResult}
                              setError_warning={setExtractResourcesResult}
                              onClick={() => loadGames()}
                            />
                          ) : null}
                        </div>
                        <div className="error_warning" style={{ maxWidth: '40rem', textAlign: 'left' }}>
                          <ErrorWarning result={extractResourcesResult} />
                        </div>
                      </div>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>

            <div ref={menuRef}>
              <Burger open={open} setOpen={setOpen} />
              <Menu
                open={open}
                onClick={() => {
                  setIsCreUnit(false);
                  setShow(true);
                }}
                grades={grades}
                setGrade={(item) => {
                  setGrade(item);
                }}
                setOpen={() => {
                  setOpen(!open);
                }}
              />
            </div>
          </div>
          <Modals
            show={show}
            setShow={(e) => setShow(e)}
            isCreUnit={isCreUnit}
            unitName={unitName}
            setUnitName={(e) => setUnitName(e)}
            folderName={folderName}
            setFolderName={(e) => setFolderName(e)}
            handleSaveUnit={() => {
              handleSaveUnit();
            }}
            handleSaveGrade={() => handleSaveGrade()}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Home;
