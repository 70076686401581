import React from 'react';
import ResultBox from './objects/ResultBoxs';
import './styles.scss';
const SearchBox = React.forwardRef(
  (props: {
    isVisible;
    inputRef;
    childRef;
    setVisible: (e) => void;
    onChange: (e) => void;
    games;
    unitId;
    onSave: () => void;
  }) => {
    const { isVisible, inputRef, childRef, setVisible, onChange, games, unitId, onSave } = props;
    return (
      <form className="my-2 my-lg-0" id="search-site">
        <input
          ref={inputRef}
          className=" form-control col-xs-3"
          type="search"
          placeholder="Tìm kiếm game chưa thuộc unit"
          aria-label="Search"
          autoComplete="off"
          autoSave="false"
          style={{ width: '50%' }}
          onChange={(e) => {
            setVisible(true);
            onChange(e);
          }}
          onFocus={(e) => {
            setVisible(true);
            onChange(e);
          }}></input>
        {isVisible && (
          <ResultBox
            games={games}
            unitId={unitId}
            refer={childRef}
            onSave={() => {
              onSave();
              setVisible(false);
            }}
          />
        )}
      </form>
    );
  },
);

export default SearchBox;
