import React from 'react';
import MonacoEditor, { loader } from '@monaco-editor/react';
import './style.scss';

interface Props {
  width: any;
  height: number;
  change: (e) => void;
  value: any;
}

const Editor: React.FC<Props> = (props) => {
  const options = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    minimap: {
      enabled: false,
    },
  };

  const editedJSON = props.value;

  loader.config({ paths: { vs: '/monaco-editor/min/vs' } });

  return (
    <div className="border-custom">
      <MonacoEditor
        width={props.width}
        height={props.height}
        options={options}
        language="json"
        theme="vs-light"
        onChange={(e) => props.change(e)}
        value={JSON.stringify(editedJSON, null, 2)}
      />
    </div>
  );
};

export default Editor;
