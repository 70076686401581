import 'bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { gameService } from '../../../../services/game/api';
import { Link } from 'react-router-dom';

const ResultBoxs = (props: { games; unitId; refer: any; onSave: () => void }) => {
  const { games, unitId, refer, onSave } = props;
  const handleAddGameToUnit = (gameId) => {
    gameService
      .insertGametoUnit(gameId, unitId)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Không có quyền truy cập');
      });
    setTimeout(() => {
      onSave();
    }, 100);
  };
  const ResultBox = ({ game, index }) => {
    const { _id, title } = game;
    return (
      <>
        <li className="py-4 px-3" key={index}>
          <Button className="float-right" onClick={() => handleAddGameToUnit(_id)}>
            Thêm
          </Button>
          <Link to={`/games/${_id}`}>
            <h3>{title}</h3>
          </Link>
        </li>
        <hr />
      </>
    );
  };
  if (games?.length > 0) {
    return (
      <ul className="wrap-suggestion" style={{ overflowY: 'auto', maxHeight: '80%' }} ref={refer}>
        {games.map((e, index) => {
          return <ResultBox game={e} index={index} key={index} />;
        })}
      </ul>
    );
  } else {
    return <></>;
  }
};
ResultBoxs.propTypes = {
  games: PropTypes.any,
  unitId: PropTypes.string,
};
export default ResultBoxs;
